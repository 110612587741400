import React, { useEffect, useState } from "react"
import { Link } from "gatsby"


/**
 * ページネーション・コンポーネント
 *
 * @param {number} currentPage - 現在のページ位置
 * @param {number} startPage? - ページの開始位置 (default: 1)
 * @param {number} endPage - ページの終了位置
 * @param {number} displayPageLinkNumber? - 表示するページリンク数 (default: 4)
 * @param {string} slug - ページリンクのスラッグ
 * @param {string} className? - スタイルクラス
 * @param {object} style? - インラインスタイル
 *
 * @poaram {boolean} debug? - ブラウザコンソールに値を表示する (default: false)
 * @return {*} React Element
 */
interface IPaginationDefaultProps {
    currentPage: number,
    startPage?: number,
    endPage: number,
    displayPageLinkNumber?: number,
    slug: string,
    className?: string,
    style?: object,
    debug?: boolean,
}

const Index = (props: IPaginationDefaultProps) => {
    const currentPage = props.currentPage
    const startPage = props.startPage !== undefined ? props.startPage : 1
    const endPage = props.endPage
    const displayPageLinkNumber = props.displayPageLinkNumber !== undefined ? props.displayPageLinkNumber : 4
    const slug = props.slug
    const className = props.className
    const style = props.style

    // ページリンク範囲の開始位置
    let pageLinkRangeStart = 0
    // ページリンク範囲の終了位置
    let pageLinkRangeEnd = 0

    const [listItems, setListItems] = useState<any>([])


    useEffect(() => {
        setPageLinkRange()
        createListItems()
    }, [])


    // ページリンクの範囲を設定する
    const setPageLinkRange = () => {
        pageLinkRangeStart = 0
        pageLinkRangeEnd = 0

        while (pageLinkRangeEnd < currentPage) {
            pageLinkRangeEnd += displayPageLinkNumber
        }

        pageLinkRangeStart = (pageLinkRangeEnd - displayPageLinkNumber) + startPage
        if (pageLinkRangeStart < startPage) pageLinkRangeStart = startPage

        if (props.debug) {
            console.log(`pageLinkRangeStart: ${pageLinkRangeStart}`)
            console.log(`pageLinkRangeEnd: ${pageLinkRangeEnd}`)
        }
    }


    // リンクリストを作成する
    const createListItems = () => {
        let items: any[] = []

        items = createPrevLink(items)
        items = createPageLink(items)
        items = createNextLink(items)

        setListItems([
            ...listItems,
            items,
        ])
    }


    // 「前のページ」リンクを作成する
    const createPrevLink = (items: any[]) => {
        let prevUrl = `${slug}/`
        const prevKey = `paginationPrevLink`

        if (currentPage <= startPage) {
            items.push(<li key={prevKey} className="disabled">前のページ</li>)
        } else {
            if (currentPage - 1 > startPage) prevUrl += `${currentPage - 1}`
            //items.push(<li key={prevKey}><a href={prevUrl} aria-label={`prev`}>前のページ</a></li>)
            items.push(<li key={prevKey}><Link to={prevUrl} aria-label={`prev`}>前のページ</Link></li>)
        }

        return items
    }


    // 「次のページ」リンクを作成する
    const createNextLink = (items: any[]) => {
        let nextUrl = `${slug}/`
        const nextKey = `paginationNextLink`

        if (currentPage >= endPage) {
            items.push(<li key={nextKey} className="disabled">次のページ</li>)
        } else {
            nextUrl += `${currentPage + 1}`
            //items.push(<li key={nextKey}><a href={nextUrl} aria-label={`prev`}>次のページ</a></li>)
            items.push(<li key={nextKey}><Link to={nextUrl} aria-label={`prev`}>次のページ</Link></li>)
        }

        return items
    }


    // ページリンクを作成する
    const createPageLink = (items: any[]) => {
        for (let i = pageLinkRangeStart; i <= pageLinkRangeEnd; i++) {
            let itemUrl = `${slug}/`
            const itemKey = `paginationPageLink${i}`

            if (i == currentPage) {
                items.push(<li key={itemKey} className="current">{i}</li>)
                continue
            }

            if (i > endPage) {
                items.push(<li key={itemKey} className="disabled">-</li>)
                continue
            }

            if (i !== startPage) itemUrl += i
            //items.push(<li key={itemKey}><a href={itemUrl} aria-label={`page ${i}`}>{i}</a></li>)
            items.push(<li key={itemKey}><Link to={itemUrl} aria-label={`page ${i}`}>{i}</Link></li>)
        }

        return items
    }


    return (
        <nav className={className} style={style} aria-label="Pagination">
            <ul className="pagination">
                {listItems}
            </ul>
        </nav>
    )
}

Index.defaultProps = {
    debug: false,
}


export default Index