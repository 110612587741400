import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"


/**
 * PageHeader Component
 * 上部でページのタイトルを表示するヘッダー部
 *
 * @param title {string} - ページのタイトル
 * @param backgroundColor: {string} - 指定がなければ BackgroundImageコンポーネントは、デフォルト値を使用する
 * @param backgroundImage {query} - 背景画像
 * @param indicators {boolean} - Scrollmagicのデバック表示を有効にする. (default: false)
 * @return {*} React Element
 */
interface IPageHeaderDefaultProps {
    title: string,
    backgroundColor?: string,
    backgroundImage?: any,
    indicators?: boolean,
}

const PageHeader = (props: IPageHeaderDefaultProps) => {
    return (
        <div className="grid-container full page-header">
            <div className="grid-x align-center-middle">
                <div className="cell">
                    <BackgroundImage
                        className="page-header__background"
                        fluid={props.backgroundImage}
                        backgroundColor={props.backgroundColor}
                        style={{
                            position: "fixed",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                        }}
                    />
                    <div className="page-header__title grid-container">
                        <div className="gird-x">
                            <div className="cell">
                                <p>{props.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
/*
<div className="grid-container page-header__body">
                        <div className="gird-x">
                            <div className="cell">
                                <p>{props.title}</p>
                            </div>
                        </div>
                    </div>

                    <div className="page-header_background">

                    </div>
 */
PageHeader.propTypes = {
    title: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.any,
    indicators: PropTypes.bool,
}

PageHeader.defaultProps = {
    title: "",
    backgroundColor: undefined,
    backgroundImage: undefined,
    indicators: false,
}


/**
 * BackgroundImage Component
 * fluid が指定されていれば<Img>要素を表示する。指定がなければ背景色のある<div>要素を表示する
 *
 * @param fluid {query} - 画像ファイルのquery
 * @param backgroundColor: {string} - 指定がなければデフォルト値を使用する
 * @return {*} React Element
 */
/*
interface IBackgroundImageDefaultProps {
    fluid?: any,
    backgroundColor?: string,
}

const BackgroundImage = (props: IBackgroundImageDefaultProps) => {
    if (props.fluid !== undefined) {
        return (
            <Img fluid={props.fluid}/>
        )
    }

    return (
        <div
            style={{
                height: "200%",
                width: "100%",
                backgroundColor: props.backgroundColor,
            }}
        />
    )
}

BackgroundImage.propTypes = {
    fluid: PropTypes.any,
    backgroundColor: PropTypes.string,
}

BackgroundImage.defaultProps = {
    fluid: undefined,
    backgroundColor: "#3498db",
}
 */


export default PageHeader