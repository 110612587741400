import React, { Fragment } from "react"
import { Link } from "gatsby"


/**
 * InformationList component
 * インフォメーション一覧
 *
 * @param {object} posts - GraphQLで取得した
 * @return {*} React Element
 */
interface IInformationListDefaultProps {
    posts: any,
    slug: string,
    style?: object,
    className?: string,
}

const InformationList = (props: IInformationListDefaultProps) => {
    type TPosts = {
        node: any,
    }

    let listItems: any[] = []
    props.posts.map(({ node }: TPosts) => {
        const date = String(node.date).split("-")
        const year = Number(date[0])
        const month = Number(date[1])
        const day = Number(date[2])

        let category = node.category
        const title = node.title
        const url = `${props.slug}/${node.directusId}-${node.date}`

        listItems.push(
            <li key={node.id} className="information-list__item">
                <Link to={url} className="__link">
                    <div>
                        <InformationCategoryTag category={category} style={{ display: "block" }}/>
                    </div>
                    <span className="__date">
                        <span className="__year">{year}年</span>
                        <span className="__month">{month}月</span>
                        <span className="__day">{day}日</span>
                    </span>
                    <span className="__title">{title}</span>
                </Link>
            </li>,
        )
    })

    return (
        <ul className={`information-list ${props.className}`} style={props.style}>
            {listItems}
        </ul>
    )
}

export default InformationList


/**
 * InformationCategoryTag component
 * インフォメーションのカテゴリタグ
 * @param {string} category - カテゴリ。Directusから取得した値。
 * @param {string} className? - スタイルクラス
 * @param {object} style? - インラインスタイル
 * @return {*} React Element
 */
interface IInformationCategoryTagDefaultProps {
    category: string,
    className?: string,
    style?: object,
}

export const InformationCategoryTag = (props: IInformationCategoryTagDefaultProps) => {
    // category == "Info"
    let categoryLabel = "お知らせ"
    let categoryStyle = {
        color: "#fff",
        backgroundColor: "rgb(72, 192, 235)",
    }

    if (props.category == "News") {
        categoryLabel = "ニュース"
        categoryStyle = {
            color: "#fff",
            backgroundColor: "rgb(242, 197, 0)",
        }
    }

    return (
        <span className={`information-category-tag ${props.className}`} style={props.style}>
            <span className="__tag" style={categoryStyle}>{categoryLabel}</span>
        </span>
    )
}
