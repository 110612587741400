import React, { Fragment, useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"


// Components
import SEO from "../components/Seo"
import LayoutBase from "../components/Layout"
import PageHeader from "../components/PageHeader"
import InformationList from "../components/InformationList"
import Pagination from "../components/Pagination"


/**
 * Information Template
 * インフォメーション一覧
 *
 * @param {any}location -
 * @param {any} data -
 * @param {pageContext} -
 * @return {*} React Element
 */
interface IInformationDefaultProps {
    location: any,
    data: any,
    pageContext: any,
}

const Information = (props: IInformationDefaultProps) => {
    const pageId = "information"
    const pageTitle = "インフォメーション"
    const location = props.location
    const { crumbs } = useBreadcrumb({
        location: location,
        crumbLabel: pageTitle,
    })

    const currentPage = props.pageContext.currentPage
    const endPage = props.pageContext.informationNumPages
    const slug = `/${pageId}`
    const headerImage = props.data.information_pageHeader_backgroundImage.childImageSharp.fluid

    // 記事一覧を取得する
    type TPosts = {
        node: any,
    }
    const posts = props.data.allDirectusInformation.edges

    return (
        <Fragment>
            <SEO pageTitle={pageTitle}/>
            <LayoutBase
                pageId={pageId}
                crumbs={crumbs}
                location={location}
            >
                <PageHeader
                    title={pageTitle}
                    backgroundImage={headerImage}
                    indicators={true}
                />
                <div className="common-content-layout common-content-format">
                    <div className="grid-container common-content-layout__section">
                        <div className="grid-x">
                            <InformationList posts={posts} slug={slug} className="cell"/>
                        </div>
                        <div className="grid-x align-center-middle text-center">
                            <div className="cell">
                                <Pagination currentPage={currentPage} endPage={endPage} slug={slug}/>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutBase>
        </Fragment>
    )
}


export default Information


export const informationListQuery = graphql`
    query BlogQuery($skip: Int!, $limit: Int!) {
        allDirectusInformation(
            limit: $limit
            skip: $skip
            sort: {
                fields:[date]
                order: [DESC]
            }
        ) {
            edges {
                node {
                    directusId
                    id
                    date
                    category
                    title
                    body
                }
            }
        },
        information_pageHeader_backgroundImage: file(relativePath: {eq: "company_pageHeader_backgroundImage.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 2500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                }
            }
        },
    }
`
